import { DeleteUserActionTypes } from "./types";
let INITIAL_STATE = {
  delete_user_loading: false,
  delete_user_error: null,
  delete_user_data: null,
};

export const deleteUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeleteUserActionTypes.DELETE_USER_LOADING:
      return { ...state, delete_user_loading: true };
    case DeleteUserActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        delete_user_loading: false,
        delete_user_data: action.payload,
      };
    case DeleteUserActionTypes.DELETE_USER_ERROR:
      return {
        ...state,
        delete_user_loading: false,
        delete_user_error: action.payload,
      };
    default:
      return state;
  }
};
