import React, { useState } from "react";
import Backdrop from "../../Backdrop/Backdrop";
import placeholder from "../../../assets/placeholder.png";
import axios from "axios";
import styles from "./AddTrusteeModal.module.css";

const AddTrusteeModal = ({ setIsOpen, trustees, setTrustees }) => {
  const [trusteeImg, setTrusteeImg] = useState(null);
  const [trusteeName, setTrusteeName] = useState("");
  const [trusteeWeb, setTrusteeWeb] = useState("");
  const [trusteeLinkedin, setTrusteeLinkedin] = useState("");
  const [loading, setLoading] = useState(false);

  let formdata = new FormData();
  const onImgChange = (event) => {
    setLoading(true);
    if (event.target.files && event.target.files[0]) {
      let imgg = event.target.files[0];
      formdata.append("image", imgg);
      axios
        .post(`https://api.tutlub.com/api/user/upload`, formdata, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setTrusteeImg(res.data.image);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleSubmit = () => {
    const currentTrustees = trustees;

    currentTrustees.push({
      image: trusteeImg,
      name: trusteeName,
      website: trusteeWeb,
      linkedin: trusteeLinkedin,
    });

    setTrustees(currentTrustees);
    setIsOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <Backdrop />
      <div className={styles.container}>
        <span className={styles.times} onClick={() => setIsOpen(false)}>
          &times;
        </span>
        <h2>Add Trustee</h2>

        <div className={styles.field}>
          <label>Image</label>
          {loading ? (
            "loading..."
          ) : trusteeImg ? (
            <div className={styles.imgContainer}>
              <img src={trusteeImg} alt={trusteeName} />
              <i className={styles.cam + " fas fa-camera"}></i>
              <input type="file" onChange={onImgChange} />
            </div>
          ) : (
            <div className={styles.imgContainer}>
              <img src={placeholder} alt="placeholder" />
              <i className={styles.cam + " fas fa-camera"}></i>
              <input type="file" onChange={onImgChange} />
            </div>
          )}
        </div>

        <div className={styles.field}>
          <label>Name</label>
          <input
            type="text"
            value={trusteeName}
            onChange={(e) => setTrusteeName(e.target.value)}
          />
        </div>

        <div className={styles.field}>
          <label>Website</label>
          <input
            type="text"
            value={trusteeWeb}
            onChange={(e) => setTrusteeWeb(e.target.value)}
          />
        </div>

        <div className={styles.field}>
          <label>Linkedin</label>
          <input
            type="text"
            value={trusteeLinkedin}
            onChange={(e) => setTrusteeLinkedin(e.target.value)}
          />
        </div>

        <button onClick={handleSubmit}>Add</button>
      </div>
    </div>
  );
};

export default AddTrusteeModal;
