import { GetFundsActionTypes } from "./types";
let INITIAL_STATE = {
  get_funds_loading: false,
  get_funds_error: null,
  get_funds_data: null,
};

export const getFundsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GetFundsActionTypes.GET_FUNDS_LOADING:
      return { ...state, get_funds_loading: true };
    case GetFundsActionTypes.GET_FUNDS_SUCCESS:
      return {
        ...state,
        get_funds_loading: false,
        get_funds_data: action.payload,
      };
    case GetFundsActionTypes.GET_FUNDS_ERROR:
      return {
        ...state,
        get_funds_loading: false,
        get_funds_error: action.payload,
      };
    default:
      return state;
  }
};
