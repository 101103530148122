import { UpdateProfileActionTypes } from "./types";
import { updateProfile } from "../../api/index";

export const UpdateProfile = (userData, token) => async (dispatch) => {
  try {
    dispatch({ type: UpdateProfileActionTypes.UPDATE_PROFILE_LOADING });
    const { data } = await updateProfile(userData, token);
    dispatch({
      type: UpdateProfileActionTypes.UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UpdateProfileActionTypes.UPDATE_PROFILE_ERROR,
      payload: error.response,
    });
  }
};
