import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { RegisterUser } from "../../../redux/register/action";
import styles from "../Auth.module.css";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { register_error, register_loading } = useSelector(
    (state) => state.registerReducer
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  // Get Token from LocalStorage
  const tokenFromLocalStorage = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  useEffect(() => {
    if (tokenFromLocalStorage) {
      navigate("/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
    });
    let errorsCopy = { ...errors };
    let isErr = false;
    if (!email) {
      isErr = true;
      errorsCopy.email = "Email is Required";
    }
    if (!password) {
      isErr = true;
      errorsCopy.password = "Password is Required";
    }
    if (!firstName) {
      isErr = true;
      errorsCopy.firstName = "First Name is Required";
    }
    if (!lastName) {
      isErr = true;
      errorsCopy.lastName = "Last Name is Required";
    }
    if (isErr) {
      setErrors({ ...errorsCopy });
    } else {
      const creds = {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      };
      dispatch(RegisterUser(creds, navigate));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}></div>
      <div className={styles.rightContainer}>
        <div className={`${styles.content} ${styles.contentRegister}`}>
          <Logo />
          <h1>Register</h1>
          <form onSubmit={handleSubmit}>
            <div className={styles.field}>
              <input
                type="text"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name *"
              />
              <small className="error">{errors.firstName}</small>
            </div>
            <div className={styles.field}>
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name *"
              />
              <small className="error">{errors.lastName}</small>
            </div>
            <div className={styles.field}>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail Address *"
              />
              <small className="error">{errors.email}</small>
            </div>
            <div className={styles.field}>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;"
              />
              <small className="error">{errors.password}</small>
            </div>
            {register_error && (
              <small className="error">{register_error.data.message}</small>
            )}
            <button>Register</button>
            <small onClick={() => navigate("/login")} className={styles.small}>
              Already have an account? Sign In!
            </small>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
