import { GetTidbitsActionTypes } from "./types";
import { getTidbits } from "../../api/index";

export const GetTidbits = (token) => async (dispatch) => {
  try {
    dispatch({ type: GetTidbitsActionTypes.GET_TIDBITS_LOADING });
    const { data } = await getTidbits(token);
    dispatch({
      type: GetTidbitsActionTypes.GET_TIDBITS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GetTidbitsActionTypes.GET_TIDBITS_ERROR,
      payload: error.response,
    });
  }
};
