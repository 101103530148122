import React from "react";
import placeholder from "../../../assets/placeholder.png";
import styles from "./UserCard.module.css";

const UserCard = ({ img, firstName, lastName, country }) => {
  return (
    <div className={styles.container}>
      <img src={img ? img : placeholder} alt={`user-${firstName}`} />
      <div className={styles.textContainer}>
        {firstName ? <p>{`${firstName} ${lastName}`}</p> : <p>None</p>}
        <small>{country ? country : "None"}</small>
      </div>
    </div>
  );
};

export default UserCard;
