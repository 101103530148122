import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { ReactComponent as Placeholder } from "../../assets/admin-placeholder.svg";
import styles from "./Profile.module.css";
import Info from "../../containers/_profile/Info/Info";
import Password from "../../containers/_profile/Password/Password";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { GetProfile } from "../../redux/getProfile/action";

const Profile = () => {
  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  const [step, setStep] = useState(1);
  const stepClasses = {
    step1: step === 1 ? styles.active : "",
    step2: step === 2 ? styles.active : "",
  };
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [currentpassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { get_profile_data, get_profile_loading } = useSelector(
    (state) => state.getProfileReducer
  );

  const { update_profile_data, update_profile_loading } = useSelector(
    (state) => state.updateProfileReducer
  );

  useEffect(() => {
    dispatch(GetProfile(token));
  }, [update_profile_data, dispatch]);

  useEffect(() => {
    if (get_profile_data && get_profile_data.data) {
      setFirstName(get_profile_data.data.first_name);
      setLastName(get_profile_data.data.last_name);
      setEmail(get_profile_data.data.email);
    }
  }, [get_profile_data]);

  return (
    <Layout>
      {!get_profile_data || get_profile_loading || update_profile_loading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className={styles.leftContainer}>
            <div className={styles.placeholder}>
              <Placeholder />
            </div>
            <h3>{`${get_profile_data.data.first_name} ${get_profile_data.data.last_name}`}</h3>
            <p>Admin</p>
          </div>

          <div className={styles.rightContainer}>
            <div className={styles.header}>
              <div
                className={`${styles.step} ${stepClasses.step1}`}
                onClick={() => setStep(1)}
              >
                <p>Information</p>
              </div>
              <div
                className={`${styles.step} ${stepClasses.step2}`}
                onClick={() => setStep(2)}
              >
                <p>Password</p>
              </div>
            </div>

            {step === 1 ? (
              <Info
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                email={email}
                setEmail={setEmail}
                token={token}
              />
            ) : (
              <Password
                currentpassword={currentpassword}
                setCurrentPassword={setCurrentPassword}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                token={token}
              />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Profile;
