import React from "react";
import Login from "../screens/Auth/Login/Login";
import Register from "../screens/Auth/Register/Register";
import Home from "../screens/Home/Home";
import Users from "../screens/Users/Users";
import Profile from "../screens/Profile/Profile";
import Tidbits from "../screens/Tidbits/Tidbits";
import SinglePost from "../screens/SinglePost/SinglePost";
import Funds from "../screens/Funds/Funds";
import FundDetail from "../screens/FundDetail/FundDetail";
import Quiz from "../screens/Quiz/Quiz";
import CreateQuiz from "../screens/CreateQuiz/CreateQuiz";
import UpdateQuiz from "../screens/UpdateQuiz/UpdateQuiz";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/users" element={<Users />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/tidbits" element={<Tidbits />} />
        <Route exact path="/tidbits/:id" element={<SinglePost />} />
        <Route exact path="/funds" element={<Funds />} />
        <Route exact path="/funds/:id" element={<FundDetail />} />
        <Route exact path="/quiz" element={<Quiz />} />
        <Route exact path="/quiz/create" element={<CreateQuiz />} />
        <Route exact path="/quiz/edit" element={<UpdateQuiz />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
