import { DeleteUserActionTypes } from "./types";
import { deleteUser } from "../../api/index";

export const DeleteUser = (id, token) => async (dispatch) => {
  try {
    dispatch({ type: DeleteUserActionTypes.DELETE_USER_LOADING });
    const { data } = await deleteUser(id, token);
    dispatch({
      type: DeleteUserActionTypes.DELETE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DeleteUserActionTypes.DELETE_USER_ERROR,
      payload: error.response,
    });
  }
};
