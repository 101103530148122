import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLastQuiz, getQuiz } from "../../api";
import Layout from "../../components/Layout/Layout";
import styles from "./Quiz.module.css";

const Quiz = () => {
  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  const navigate = useNavigate();
  const [quizDetails, setQuizDetails] = useState();

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const res = await getQuiz(token);

        if (res.data.message !== "No Upcoming Quiz") {
          setQuizDetails(res.data.data[0]);
        } else {
          const lastQuiz = await getLastQuiz(token);
          setQuizDetails(lastQuiz.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchQuiz();
  }, []);

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Quiz</h1>
          <button onClick={() => navigate("/quiz/edit")}>Update</button>
          <button onClick={() => navigate("/quiz/create")}>Create</button>
        </div>

        <div className={styles.card}>
          <h4>General Info</h4>

          <div className={styles.field}>
            <label>Quiz Date</label>
            <p>
              {quizDetails &&
                format(new Date(quizDetails.quiz_date), "dd, MMM, yyyy")}
            </p>
          </div>

          <div className={styles.field}>
            <label>Win Price</label>
            <p>{quizDetails && quizDetails.win_price}</p>
          </div>
        </div>

        <div className={styles.card}>
          <h4>Questions</h4>

          {quizDetails &&
            quizDetails.questions.map((i, idx) => (
              <div className={styles.field}>
                <label>
                  Q{idx + 1}: {i.questions}
                </label>
                <p className={styles.option}>Option 1: {i.option1}</p>
                <p className={styles.option}>Option 2: {i.option2}</p>
                <p className={styles.option}>Option 3: {i.option3}</p>
                <p className={styles.option}>Option 4: {i.option4}</p>
                <p className={styles.option}>Answer: {i.answer}</p>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default Quiz;
