import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import Create from "../../components/_funds/Create/Create";
import Nisab from "../../components/_funds/Nisab/Nisab";
import Cards from "../../containers/_funds/Cards/Cards";
import styles from "./Funds.module.css";

const Funds = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Funds</h1>
          <button onClick={() => setIsOpen2(true)}>Update Nisab</button>
          <button onClick={() => setIsOpen(true)}>Create</button>
        </div>

        <Cards token={token} />
      </div>
      {isOpen && <Create setIsOpen={setIsOpen} />}
      {isOpen2 && <Nisab setIsOpen={setIsOpen2} />}
    </Layout>
  );
};

export default Funds;
