import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { UpdateProfile } from "../../../redux/updateProfile/action";
import styles from "../ProfileCards.module.css";

const Password = ({
  password,
  setPassword,
  currentpassword,
  setCurrentPassword,
  confirmPassword,
  setConfirmPassword,
  token,
}) => {
  const dispatch = useDispatch();

  const [passError, setPassError] = useState(null);
  const [currError, setCurrError] = useState("");
  const [confirmError, setConfirmError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    setPassError("")
    setCurrError("")
    setConfirmError("")

    if (!password) {
      setPassError("Please enter the password");
    }
    if (!currentpassword) {
      setCurrError("Please enter the current password");
    }
    if (!confirmPassword) {
      setConfirmError("Please enter the new password again");
    }
    if (password && currentpassword && confirmPassword) {
      const payload = { password };
      dispatch(UpdateProfile(payload, token));
    }
  };
  return (
    <div className={styles.container}>
      <h4>Change your password</h4>

      <form onSubmit={handleSubmit}>
        <div className={styles.field}>
          <input
            type="password"
            name="currentPassword"
            placeholder="Current Password*"
            value={currentpassword}
            onChange={(e) => {
              setCurrError("");
              setCurrentPassword(e.target.value);
            }}
          />
          <small className="error">{currError}</small>
        </div>
        <div className={styles.field}>
          <input
            type="password"
            name="newPassword"
            placeholder="New Password*"
            value={password}
            onChange={(e) => {
              setPassError("");
              setPassword(e.target.value);
            }}
          />
          <small className="error">{passError}</small>
        </div>
        <div className={styles.field}>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password*"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmError("");
              setConfirmPassword(e.target.value);
            }}
          />
          <small className="error">{confirmError}</small>
        </div>
        <div className={styles.btnContainer}>
          <button type="submit">Update</button>
        </div>
      </form>
    </div>
  );
};

export default Password;
