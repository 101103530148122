import React from "react";
import Layout from "../../components/Layout/Layout";
import { ReactComponent as Vector } from "../../assets/home/vector.svg";
import ProfileCard from "../../components/_home/ProfileCard/ProfileCard";
import TidbitsCard from "../../components/_home/TidbitsCard/TidbitsCard";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetUsers } from "../../redux/getUsers/action";
import { GetProfile } from "../../redux/getProfile/action";
import Users from "../../containers/_home/Users/Users";
import Loader from "../../components/Loader/Loader";
import styles from "./Home.module.css";
import Funds from "../../containers/_home/Funds/Funds";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { get_users_data, get_users_loading } = useSelector(
    (state) => state.getUsersReducer
  );

  const { get_profile_data, get_profile_loading } = useSelector(
    (state) => state.getProfileReducer
  );

  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    dispatch(GetUsers(token));
    dispatch(GetProfile(token));
  }, []);

  return (
    <Layout>
      {!get_users_data ||
      get_users_loading ||
      !get_profile_data ||
      get_profile_loading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className={styles.leftContainer}>
            <div className={styles.textContainer}>
              <h1>Hi {get_profile_data.data.first_name},</h1>
              <h1>Welcome back!</h1>
              <p>
                This page is designed to give some important information about
                the application. Let's make someting together!
              </p>
              <Vector />
            </div>
            <ProfileCard
              firstName={get_profile_data.data.first_name}
              lastName={get_profile_data.data.last_name}
              email={get_profile_data.data.email}
            />
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.rightTop}>
              <Funds />
              <TidbitsCard />
            </div>
            <Users data={get_users_data} />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Home;
