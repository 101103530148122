import { UpdateNisabActionTypes } from "./types";
import { updateNisab } from "../../api/index";

export const UpdateNisab = (token, id, userData) => async (dispatch) => {
  try {
    dispatch({ type: UpdateNisabActionTypes.UPDATE_NISAB_LOADING });
    const { data } = await updateNisab(token, id, userData);
    dispatch({
      type: UpdateNisabActionTypes.UPDATE_NISAB_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UpdateNisabActionTypes.UPDATE_NISAB_ERROR,
      payload: error.response,
    });
  }
};
