import { DeleteFundActionTypes } from "./types";
import { deleteFund } from "../../api/index";

export const DeleteFund = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: DeleteFundActionTypes.DELETE_FUND_LOADING });
    const { data } = await deleteFund(token, id);
    dispatch({
      type: DeleteFundActionTypes.DELETE_FUND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DeleteFundActionTypes.DELETE_FUND_ERROR,
      payload: error.response,
    });
  }
};
