import React from "react";
import { useDispatch } from "react-redux";
import { UpdateProfile } from "../../../redux/updateProfile/action";
import styles from "../ProfileCards.module.css";

const Info = ({
  firstName,
  lastName,
  email,
  setFirstName,
  setLastName,
  setEmail,
  token,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault()

    const payload = {
      email,
      first_name: firstName,
      last_name: lastName,
    }
    dispatch(UpdateProfile(payload, token))
  }

  return (
    <div className={styles.container}>
      <h4>Update Profile</h4>

      <form onSubmit={handleSubmit}>
        <div className={styles.field}>
          <input
            type="text"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className={styles.field}>
          <input
            type="text"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className={styles.field}>
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={styles.btnContainer}>
          <button type="submit">Update</button>
        </div>
      </form>
    </div>
  );
};

export default Info;
