import { DeleteTidbitActionTypes } from "./types";
import { deleteTidbit } from "../../api/index";

export const DeleteTidbit = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: DeleteTidbitActionTypes.DELETE_TIDBIT_LOADING });
    const { data } = await deleteTidbit(token, id);
    dispatch({
      type: DeleteTidbitActionTypes.DELETE_TIDBIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DeleteTidbitActionTypes.DELETE_TIDBIT_ERROR,
      payload: error.response,
    });
  }
};
