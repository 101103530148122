import { GetFundByIDActionTypes } from "./types";
import { getFundByID } from "../../api/index";

export const GetFundByID = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: GetFundByIDActionTypes.GET_FUND_BY_ID_LOADING });
    const { data } = await getFundByID(token, id);
    dispatch({
      type: GetFundByIDActionTypes.GET_FUND_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GetFundByIDActionTypes.GET_FUND_BY_ID_ERROR,
      payload: error.response,
    });
  }
};
