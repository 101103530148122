import { DeleteTidbitActionTypes } from "./types";
let INITIAL_STATE = {
  delete_tidbit_loading: false,
  delete_tidbit_error: null,
  delete_tidbit_data: null,
};

export const deleteTidbitReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeleteTidbitActionTypes.DELETE_TIDBIT_LOADING:
      return { ...state, delete_tidbit_loading: true };
    case DeleteTidbitActionTypes.DELETE_TIDBIT_SUCCESS:
      return {
        ...state,
        delete_tidbit_loading: false,
        delete_tidbit_data: action.payload,
      };
    case DeleteTidbitActionTypes.DELETE_TIDBIT_ERROR:
      return {
        ...state,
        delete_tidbit_loading: false,
        delete_tidbit_error: action.payload,
      };
    default:
      return state;
  }
};
