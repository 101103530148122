import { GetUsersActionTypes } from "./types";
let INITIAL_STATE = {
  get_users_loading: false,
  get_users_error: null,
  get_users_data: null,
};

export const getUsersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GetUsersActionTypes.GET_USERS_LOADING:
      return { ...state, get_users_loading: true };
    case GetUsersActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        get_users_loading: false,
        get_users_data: action.payload,
      };
    case GetUsersActionTypes.GET_USERS_ERROR:
      return {
        ...state,
        get_users_loading: false,
        get_users_error: action.payload,
      };
    default:
      return state;
  }
};
