import { CreateTidbitActionTypes } from "./types";
let INITIAL_STATE = {
  create_tidbit_loading: false,
  create_tidbit_error: null,
  create_tidbit_data: null,
};

export const createTidbitReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreateTidbitActionTypes.CREATE_TIDBIT_LOADING:
      return { ...state, create_tidbit_loading: true };
    case CreateTidbitActionTypes.CREATE_TIDBIT_SUCCESS:
      return {
        ...state,
        create_tidbit_loading: false,
        create_tidbit_data: action.payload,
      };
    case CreateTidbitActionTypes.CREATE_TIDBIT_ERROR:
      return {
        ...state,
        create_tidbit_loading: false,
        create_tidbit_error: action.payload,
      };
    default:
      return state;
  }
};
