import { GetNisabActionTypes } from "./types";
import { getNisab } from "../../api/index";

export const GetNisab = (token) => async (dispatch) => {
  try {
    dispatch({ type: GetNisabActionTypes.GET_NISAB_LOADING });
    const { data } = await getNisab(token);
    dispatch({
      type: GetNisabActionTypes.GET_NISAB_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GetNisabActionTypes.GET_NISAB_ERROR,
      payload: error.response,
    });
  }
};
