import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetFundByID } from "../../redux/getFundByID/action";
import Loader from "../../components/Loader/Loader";
import { UpdateFund } from "../../redux/updateFund/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditTrusteeModal from "../../components/_funds/EditTrusteeModal/EditTrusteeModal";
import AddTrusteeModal from "../../components/_funds/AddTrusteeModal/AddTrusteeModal";
import { format } from "date-fns";
import styles from "./FundDetail.module.css";

const FundDetail = () => {
  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [convertedText, setConvertedText] = useState("");
  const [deadline, setDeadline] = useState(null);
  const [amountNaira, setAmountNaira] = useState(0);
  const [amountPound, setAmountPound] = useState(0);
  const [status, setStatus] = useState();
  const [trustees, setTrustees] = useState([]);
  const [trusteeName, setTrusteeName] = useState();
  const [trusteeImage, setTrusteeImage] = useState("");
  const [trusteeWebsite, setTrusteeWebsite] = useState();
  const [trusteeLinkedin, setTrusteeLinkedin] = useState();

  const [isEdit, setIsEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [trusteeID, setTrusteeID] = useState(null);

  const { get_fund_by_id_data, get_fund_by_id_loading } = useSelector(
    (state) => state.getFundByIDReducer
  );

  const { update_fund_data, update_fund_loading } = useSelector(
    (state) => state.updateFundReducer
  );

  useEffect(() => {
    dispatch(GetFundByID(token, id));
  }, [dispatch, update_fund_data, update_fund_loading]);

  useEffect(() => {
    if (get_fund_by_id_data) {
      setTitle(get_fund_by_id_data.data.fund.title);
      setConvertedText(get_fund_by_id_data.data.fund.description);
      setDeadline(get_fund_by_id_data.data.fund.deadline_date);
      setAmountNaira(get_fund_by_id_data.data.fund.amount_required_in_naira);
      setAmountPound(get_fund_by_id_data.data.fund.amount_required_in_pound);
      setStatus(get_fund_by_id_data.data.fund.status === "live");
      setTrustees(get_fund_by_id_data.data.fund.trustees);
    }
  }, [get_fund_by_id_data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      title,
      description: convertedText,
      deadline_date: deadline,
      amount_required_in_naira: amountNaira,
      amount_required_in_pound: amountPound,
      status: status ? "live" : "inactive",
      trustees,
    };
    dispatch(UpdateFund(token, id, payload));
    setIsEdit(false);
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
  ];

  const saveTrustee = (e) => {
    e.preventDefault();

    const payload = {
      title,
      description: convertedText,
      deadline_date: deadline,
      amount_required_in_naira: amountNaira,
      amount_required_in_pound: amountPound,
      status: status ? "live" : "inactive",
      trustees,
    };
    dispatch(UpdateFund(token, id, payload));
  };

  const handleDelete = (id) => {
    setIsModalOpen(false);
    const updatedArray = trustees.filter((i) => i._id !== id);
    setTrustees(updatedArray);
  };

  return (
    <Layout>
      {!get_fund_by_id_data || get_fund_by_id_loading || update_fund_loading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          {/* TITLE */}
          <div className={styles.header}>
            {!isEdit ? (
              // <h1>{get_tidbits_by_id_data.data.title}</h1>
              <h1>{get_fund_by_id_data.data.fund.title}</h1>
            ) : (
              <div className={styles.field}>
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            )}

            {!isEdit && <button onClick={() => setIsEdit(true)}>Edit</button>}
          </div>

          {/* DESCRIPTION */}
          {!isEdit ? (
            <div className={styles.body} style={{ marginBottom: "30px" }}>
              <label>Description</label>
              <div
                dangerouslySetInnerHTML={{
                  __html: get_fund_by_id_data.data.fund.description,
                }}
              />
            </div>
          ) : (
            <div className={styles.body}>
              <div className={styles.field}>
                <label>Description</label>
                <div className={styles.quillContainer}>
                  <ReactQuill
                    theme="snow"
                    value={convertedText}
                    onChange={setConvertedText}
                    style={{ minHeight: "300px" }}
                    formats={formats}
                  />
                </div>
              </div>
            </div>
          )}

          {/* AMOUNT GENERATED IN NAIRA */}
          {get_fund_by_id_data.data.fund.amount_generated_in_naira && (
            <div className={styles.field} style={{ marginBottom: "30px" }}>
              <label>Amount Generated In Naira</label>
              <p>
                {get_fund_by_id_data.data.fund.amount_generated_in_naira?.toFixed(
                  3
                )}
              </p>
            </div>
          )}

          {/* AMOUNT GENERATED IN POUND */}
          {get_fund_by_id_data.data.fund.amount_generated_in_pound && (
            <div className={styles.field} style={{ marginBottom: "30px" }}>
              <label>Amount Generated In Pound</label>
              <p>
                {get_fund_by_id_data.data.fund.amount_generated_in_pound?.toFixed(
                  3
                )}
              </p>
            </div>
          )}

          {/* AMOUNT REQUIRED IN NAIRA */}
          {!isEdit ? (
            <div className={styles.field} style={{ marginBottom: "30px" }}>
              <label>Amount Required In Naira</label>
              <p>{get_fund_by_id_data.data.fund.amount_required_in_naira}</p>
            </div>
          ) : (
            <div className={styles.field}>
              <label>Amount Required In Naira</label>
              <input
                type="number"
                name="amount"
                value={amountNaira}
                onChange={(e) => setAmountNaira(e.target.value)}
              />
            </div>
          )}

          {/* AMOUNT REQUIRED IN POUND */}
          {!isEdit ? (
            <div className={styles.field} style={{ marginBottom: "30px" }}>
              <label>Amount Required In Pound</label>
              <p>{get_fund_by_id_data.data.fund.amount_required_in_pound}</p>
            </div>
          ) : (
            <div className={styles.field}>
              <label>Amount Required In Pound</label>
              <input
                type="number"
                name="amount"
                value={amountPound}
                onChange={(e) => setAmountPound(e.target.value)}
              />
            </div>
          )}

          {/* DEADLINE */}
          {!isEdit ? (
            <div className={styles.field} style={{ marginBottom: "30px" }}>
              <label>Deadline</label>
              <p>
                {format(
                  new Date(get_fund_by_id_data.data.fund.deadline_date),
                  "dd, MMMM, yyyy"
                )}
              </p>
            </div>
          ) : (
            <div className={styles.field}>
              <label>Deadline</label>
              <DatePicker
                selected={new Date(deadline)}
                minDate={new Date()}
                showDisabledMonthNavigation
                onChange={(date) => setDeadline(date)}
              />
            </div>
          )}

          {/* TRUSTEES */}
          <div className={styles.field}>
            <label>Trustees</label>
            {trustees &&
              trustees.length > 0 &&
              trustees.map((i) => (
                <div className={styles.trustee} key={i._id}>
                  <i
                    className={styles.edit + " fas fa-edit"}
                    onClick={() => {
                      setIsModalOpen(true);
                      setTrusteeID(i._id);
                    }}
                  ></i>
                  <i
                    className={styles.delete + " fas fa-trash"}
                    onClick={() => handleDelete(i._id)}
                  ></i>
                  <img src={i.image} alt={i.name} />
                  <p>{i.name}</p>
                  <p>{i.website}</p>
                  <p>{i.linkedin}</p>
                </div>
              ))}
            <button
              className={styles.add}
              onClick={() => setIsModalOpen2(true)}
            >
              Add Trustee
            </button>
            {trustees && trustees.length > 0 && (
              <button className={styles.save} onClick={saveTrustee}>
                Save Trustee
              </button>
            )}
          </div>

          {/* STATUS */}
          {!isEdit ? (
            <div className={styles.field}>
              <label>Status</label>
              <p>{get_fund_by_id_data.data.fund.status}</p>
            </div>
          ) : (
            <div className={styles.field}>
              <label>Status</label>
              <input
                type="checkbox"
                name="status"
                required
                value={status}
                checked={status}
                onChange={(e) => setStatus(!status)}
              />
            </div>
          )}

          {isEdit && (
            <button className={styles.submit} onClick={handleSubmit}>
              Update
            </button>
          )}
        </div>
      )}
      {isModalOpen && (
        <EditTrusteeModal
          setIsOpen={setIsModalOpen}
          trusteeID={trusteeID}
          trustees={trustees}
          setTrustees={setTrustees}
        />
      )}
      {isModalOpen2 && (
        <AddTrusteeModal
          setIsOpen={setIsModalOpen2}
          trusteeID={trusteeID}
          trustees={trustees}
          setTrustees={setTrustees}
        />
      )}
    </Layout>
  );
};

export default FundDetail;
