import { GetTidbitsByIDActionTypes } from "./types";
import { getTidbitByID } from "../../api/index";

export const GetTidbitByID = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: GetTidbitsByIDActionTypes.GET_TIDBITS_BY_ID_LOADING });
    const { data } = await getTidbitByID(token, id);
    dispatch({
      type: GetTidbitsByIDActionTypes.GET_TIDBITS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GetTidbitsByIDActionTypes.GET_TIDBITS_BY_ID_ERROR,
      payload: error.response,
    });
  }
};
