import { UpdateTidbitActionTypes } from "./types";
let INITIAL_STATE = {
  update_tidbit_loading: false,
  update_tidbit_error: null,
  update_tidbit_data: null,
};

export const updateTidbitReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UpdateTidbitActionTypes.UPDATE_TIDBIT_LOADING:
      return { ...state, update_tidbit_loading: true };
    case UpdateTidbitActionTypes.UPDATE_TIDBIT_SUCCESS:
      return {
        ...state,
        update_tidbit_loading: false,
        update_tidbit_data: action.payload,
      };
    case UpdateTidbitActionTypes.UPDATE_TIDBIT_ERROR:
      return {
        ...state,
        update_tidbit_loading: false,
        update_tidbit_error: action.payload,
      };
    default:
      return state;
  }
};
