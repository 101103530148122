import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import Card from "../../../components/_funds/Card/Card";
import { GetFunds } from "../../../redux/getFunds/action";
import styles from "./Cards.module.css";

const Cards = ({ token }) => {
  const dispatch = useDispatch();
  const { get_funds_data, get_funds_loading } = useSelector(
    (state) => state.getFundsReducer
  );

  const { create_fund_data, create_fund_loading } = useSelector(
    (state) => state.createFundReducer
  );

  const { delete_fund_data, delete_fund_loading } = useSelector(
    (state) => state.deleteFundReducer
  );

  const { update_fund_data, update_fund_loading } = useSelector(
    (state) => state.updateFundReducer
  );

  useEffect(() => {
    dispatch(GetFunds(token));
  }, [dispatch, create_fund_data, delete_fund_data, update_fund_data]);

  return (
    <div className={styles.container}>
      {get_funds_loading ||
      create_fund_loading ||
      delete_fund_loading ||
      update_fund_loading ||
      !get_funds_data ? (
        <Loader />
      ) : (
        get_funds_data.data.allFunds.map((i) => (
          <Card
            id={i._id}
            token={token}
            title={i.title}
            description={i.description}
            status={i.status}
          />
        ))
      )}
    </div>
  );
};

export default Cards;
