import { UpdateProfileActionTypes } from "./types";
let INITIAL_STATE = {
  update_profile_loading: false,
  update_profile_error: null,
  update_profile_data: null,
};

export const updateProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UpdateProfileActionTypes.UPDATE_PROFILE_LOADING:
      return { ...state, update_profile_loading: true };
    case UpdateProfileActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        update_profile_loading: false,
        update_profile_data: action.payload,
      };
    case UpdateProfileActionTypes.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        update_profile_loading: false,
        update_profile_error: action.payload,
      };
    default:
      return state;
  }
};
