import React, { useState } from "react";
import Backdrop from "../../Backdrop/Backdrop";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CreateTidbit } from "../../../redux/createTidbit/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Create.module.css";

const Create = ({ setIsOpen }) => {
  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [convertedText, setConvertedText] = useState("Some default content");
  const [startDate, setStartDate] = useState(new Date());

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      title,
      body: convertedText,
      date: startDate
    };
    dispatch(CreateTidbit(token, payload));
    setIsOpen(false)
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
  ];

  return (
    <div className={styles.mainContainer}>
      <Backdrop />
      <div className={styles.container}>
        <span className={styles.times} onClick={() => setIsOpen(false)}>
          &times;
        </span>
        <form onSubmit={handleSubmit}>
          <div className={styles.field}>
            <label>Title</label>
            <input
              type="text"
              placeholder="Write something"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <label>Body</label>
            <div>
              <ReactQuill
                theme="snow"
                value={convertedText}
                onChange={setConvertedText}
                style={{ minHeight: "300px" }}
                formats={formats}
              />
            </div>
          </div>

          <div className={styles.field}>
            <label>Select Date</label>
            <DatePicker
              selected={startDate}
              minDate={new Date()}
              showDisabledMonthNavigation
              onChange={(date) => setStartDate(date)}
            />
          </div>

          <button type="submit">Post</button>
        </form>
      </div>
    </div>
  );
};

export default Create;
