import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import Card from "../../../components/_tidbits/Card/Card";
import { GetTidbits } from "../../../redux/getTidbits/action";
import styles from "./Cards.module.css";

const Cards = ({ token }) => {
  const dispatch = useDispatch();

  const { get_tidbits_data, get_tidbits_loading } = useSelector(
    (state) => state.getTidbitsReducer
  );

  const { delete_tidbit_data, delete_tidbit_loading } = useSelector(
    (state) => state.deleteTidbitReducer
  );

  const { create_tidbit_data, create_tidbit_loading } = useSelector(
    (state) => state.createTidbitReducer
  );

  useEffect(() => {
    dispatch(GetTidbits(token));
  }, [delete_tidbit_data, create_tidbit_data, dispatch]);

  return (
    <div className={styles.container}>
      {!get_tidbits_data ||
      get_tidbits_loading ||
      delete_tidbit_loading ||
      create_tidbit_loading ? (
        <Loader />
      ) : (
        get_tidbits_data.data.map((i) => (
          <Card
            title={i.title}
            id={i._id}
            key={i._id}
            body={i.body}
            token={token}
            date={i.date}
          />
        ))
      )}
    </div>
  );
};

export default Cards;
