import { CreateFundActionTypes } from "./types";
let INITIAL_STATE = {
  create_fund_loading: false,
  create_fund_error: null,
  create_fund_data: null,
};

export const createFundReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreateFundActionTypes.CREATE_FUND_LOADING:
      return { ...state, create_fund_loading: true };
    case CreateFundActionTypes.CREATE_FUND_SUCCESS:
      return {
        ...state,
        create_fund_loading: false,
        create_fund_data: action.payload,
      };
    case CreateFundActionTypes.CREATE_FUND_ERROR:
      return {
        ...state,
        create_fund_loading: false,
        create_fund_error: action.payload,
      };
    default:
      return state;
  }
};
