import React from 'react'
import FundCard from '../../../components/_home/FundCard/FundCard';
import styles from "./Funds.module.css";

const Funds = () => {
  return (
    <div className={styles.container}>
      <FundCard amount="₦ 150,000.00" name="Education Fund" />
      <FundCard amount="₦ 125,000.00" name="Health Fund" />
      <FundCard amount="₦ 130,000.00" name="Welfare Fund" />
    </div>
  )
}

export default Funds