import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./SinglePost.module.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetTidbitByID } from "../../redux/getTidbitsByID/action";
import Loader from "../../components/Loader/Loader";
import { UpdateTidbit } from "../../redux/updateTidbit/action";

const SinglePost = () => {
  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [convertedText, setConvertedText] = useState("");

  const [isEdit, setIsEdit] = useState(false);

  const { get_tidbits_by_id_data, get_tidbits_by_id_loading } = useSelector(
    (state) => state.getTidbitsByIDReducer
  );

  const { update_tidbit_data, update_tidbit_loading } = useSelector(
    (state) => state.updateTidbitReducer
  );

  useEffect(() => {
    dispatch(GetTidbitByID(token, id));
  }, [dispatch, update_tidbit_data, update_tidbit_loading]);

  useEffect(() => {
    if (get_tidbits_by_id_data) {
      setTitle(get_tidbits_by_id_data.data.title);
      setConvertedText(get_tidbits_by_id_data.data.body);
    }
  }, [get_tidbits_by_id_data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      title,
      body: convertedText,
    };
    dispatch(UpdateTidbit(token, id, payload));
    setIsEdit(false);
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
  ];
  return (
    <Layout>
      {!get_tidbits_by_id_data ||
      get_tidbits_by_id_loading ||
      update_tidbit_loading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            {!isEdit ? (
              <h1>{get_tidbits_by_id_data.data.title}</h1>
            ) : (
              <div className={styles.field}>
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            )}

            {!isEdit && <button onClick={() => setIsEdit(true)}>Edit</button>}
          </div>

          {!isEdit ? (
            <div className={styles.body}>
              <div
                dangerouslySetInnerHTML={{
                  __html: get_tidbits_by_id_data.data.body,
                }}
              />
            </div>
          ) : (
            <div className={styles.body}>
              <div className={styles.field}>
                <label>Body</label>
                <div className={styles.quillContainer}>
                  <ReactQuill
                    theme="snow"
                    value={convertedText}
                    onChange={setConvertedText}
                    style={{ minHeight: "300px" }}
                    formats={formats}
                  />
                </div>
              </div>
            </div>
          )}

          {isEdit && (
            <button className={styles.submit} onClick={handleSubmit}>
              Submit
            </button>
          )}
        </div>
      )}
    </Layout>
  );
};

export default SinglePost;
