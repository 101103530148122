import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import styles from "./Card.module.css";
import { useDispatch } from "react-redux";
import { DeleteFund } from "../../../redux/deleteFund/action";

const Card = ({ id, title, description, status, token }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = () => dispatch(DeleteFund(token, id));

  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      <div className={styles.body}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <p className={styles.readMore} onClick={() => navigate(`/funds/${id}`)}>
        Read More <i className="fas fa-angle-right"></i>
      </p>
      <p className={styles.trash} onClick={handleDelete}>
        Delete
      </p>
      {/* <small><i className="fas fa-clock"></i>{" "}{format(new Date(date), 'EEE, dd MMM yyyy')}</small> */}
    </div>
  );
};

export default Card;
