import { CreateTidbitActionTypes } from "./types";
import { createTidbit } from "../../api/index";

export const CreateTidbit = (token, userData) => async (dispatch) => {
  try {
    dispatch({ type: CreateTidbitActionTypes.CREATE_TIDBIT_LOADING });
    const { data } = await createTidbit(token, userData);
    dispatch({
      type: CreateTidbitActionTypes.CREATE_TIDBIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CreateTidbitActionTypes.CREATE_TIDBIT_ERROR,
      payload: error.response,
    });
  }
};
