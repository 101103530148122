import axios from "axios";
// const baseURL = "https://www.tutlub.com/api/admin";
const baseURL = "https://api.tutlub.com/api/admin";

// LOGIN
export const login = (credentials) =>
  axios.post(`${baseURL}/login`, credentials, {
    headers: {
      "Content-Type": "application/json",
    },
  });

// REGISTER
export const register = (credentials) =>
  axios.post(`${baseURL}/register`, credentials, {
    headers: {
      "Content-Type": "application/json",
    },
  });

// GET PROFILE
export const getProfile = (token) =>
  axios.get(`${baseURL}/me`, {
    headers: {
      "x-auth-token": token,
    },
  });

// UPDATE PROFILE
export const updateProfile = (data, token) =>
  axios.put(`${baseURL}/me`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });

// GET ALL USERS
export const getUsers = (token) =>
  axios.get(`${baseURL}/users`, {
    headers: {
      "x-auth-token": token,
    },
  });

// DELETE USER
export const deleteUser = (id, token) =>
  axios.delete(`${baseURL}/users/${id}`, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });

// GET ALL TIDBITS
export const getTidbits = (token) =>
  axios.get(`${baseURL}/tidbits/`, {
    headers: {
      "x-auth-token": token,
    },
  });

// GET TIDBIT BY ID
export const getTidbitByID = (token, id) =>
  axios.get(`${baseURL}/tidbits/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });

// CREATE TIDBIT
export const createTidbit = (token, data) =>
  axios.post(`${baseURL}/tidbits/`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });

// DELETE TIDBIT
export const deleteTidbit = (token, id) =>
  axios.delete(`${baseURL}/tidbits/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });

// UPDATE TIDBIT
export const updateTidbit = (token, id, data) =>
  axios.put(`${baseURL}/tidbits/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });

// GET ALL FUNDS
export const getFunds = (token) =>
  axios.get(`${baseURL}/funds`, {
    headers: {
      "x-auth-token": token,
    },
  });

// CREATE FUND
export const createFund = (token, data) =>
  axios.post(`${baseURL}/funds`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });

// DELETE FUND
export const deleteFund = (token, id) =>
  axios.delete(`${baseURL}/funds/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });

// GET FUND BY ID
export const getFundByID = (token, id) =>
  axios.get(`${baseURL}/funds/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });

// UPDATE FUND
export const updateFund = (token, id, data) =>
  axios.put(`${baseURL}/funds/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });

// UPLOAD IMAGE
export const uploadMedia = async (data) =>
  axios.post(`https://api.tutlub.com/api/user/upload`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

// UPDATE NISAB
export const updateNisab = (token, id, data) =>
  axios.put(`${baseURL}/nisab/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });

// GET NISAB
export const getNisab = (token) =>
  axios.get(`${baseURL}/nisab`, {
    headers: {
      "x-auth-token": token,
    },
  });

// CREATE QUIZ
export const createQuiz = (token, data) =>
  axios.post(`https://api.tutlub.com/api/quiz/`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });

// GET QUIZ
export const getQuiz = (token) =>
  axios.get(`https://api.tutlub.com/api/quiz/`, {
    headers: {
      "x-auth-token": token,
    },
  });

// GET LAST  QUIZ
export const getLastQuiz = (token) =>
  axios.get(`https://api.tutlub.com/api/quiz/last/results`, {
    headers: {
      "x-auth-token": token,
    },
  });

// UPDATE QUIZ
export const updateQuiz = (token, data) =>
  axios.put(`https://api.tutlub.com/api/quiz/`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });
