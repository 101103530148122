import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { ReactComponent as Placeholder } from "../../../assets/admin-placeholder.svg";
import styles from "./SideSection.module.css";

const SideSection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = {
    home: location.pathname === "/" ? styles.active : "",
    dashboard: location.pathname === "/dashboard" ? styles.active : "",
    users: location.pathname === "/users" ? styles.active : "",
    tidbits: location.pathname === "/tidbits" ? styles.active : "",
    funds: location.pathname === "/funds" ? styles.active : "",
    chat: location.pathname === "/chat" ? styles.active : "",
    profile: location.pathname === "/profile" ? styles.active : "",
    quiz: location.pathname === "/quiz" ? styles.active : "",
  };
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      <ul>
        <li className={classes.home} onClick={() => navigate("/")}>
          <i className="fas fa-home"></i>
          <p>Home</p>
        </li>
        <li
          style={{ cursor: "not-allowed" }}
          title="Coming Soon"
          className={classes.dashboard}
        >
          <i className="fas fa-chart-line"></i>
          <p>Dashboard</p>
        </li>
        <li className={classes.users} onClick={() => navigate("/users")}>
          <i className="fas fa-user-friends"></i>
          <p>Users</p>
        </li>
        <li className={classes.tidbits} onClick={() => navigate("/tidbits")}>
          <i className="fas fa-project-diagram"></i>
          <p>Tidbits</p>
        </li>
        <li className={classes.funds} onClick={() => navigate("/funds")}>
          <i className="fas fa-project-diagram"></i>
          <p>Funds</p>
        </li>
        <li
          style={{ cursor: "not-allowed" }}
          title="Coming Soon"
          className={classes.chat}
        >
          <i className="fas fa-envelope"></i>
          <p>Chat</p>
        </li>
        <li className={classes.quiz} onClick={() => navigate("/quiz")}>
        <i className="fas fa-project-diagram"></i>
          <p>Quiz</p>
        </li>
        <li className={classes.profile} onClick={() => navigate("/profile")}>
          <Placeholder />
          <p>Profile</p>
        </li>
      </ul>
      <small>&copy; Copyright Tutlub - 2022</small>
    </div>
  );
};

export default SideSection;
