import React from "react";
import styles from "./TidbitsCard.module.css";

const TidbitsCard = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>Tidbits</h4>
      </div>
      <div className={styles.body}>
        <ul>
          <li>
            <h5>On Financial Budgeting</h5>
            <small>
              Allah is the Master Planner. It is recommended that we plan for
              ourselves short and long-term goal
            </small>
          </li>
          <li>
            <h5>Lorem ipsum dolor</h5>
            <small>In ultrices hendrerit risus, vitae mollis orci</small>
          </li>
          <li>
            <h5>Etiam tincidunt fermentum</h5>
            <small>Curabitur lacinia nisi metus.</small>
          </li>
          <li>
            <h5>On Financial Budgeting</h5>
            <small>In ultrices hendrerit risus, vitae mollis orci</small>
          </li>
        </ul>

        <button>View all</button>
      </div>
    </div>
  );
};

export default TidbitsCard;
