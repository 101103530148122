import { UpdateFundActionTypes } from "./types";
let INITIAL_STATE = {
  update_fund_loading: false,
  update_fund_error: null,
  update_fund_data: null,
};

export const updateFundReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UpdateFundActionTypes.UPDATE_FUND_LOADING:
      return { ...state, update_fund_loading: true };
    case UpdateFundActionTypes.UPDATE_FUND_SUCCESS:
      return {
        ...state,
        update_fund_loading: false,
        update_fund_data: action.payload,
      };
    case UpdateFundActionTypes.UPDATE_FUND_ERROR:
      return {
        ...state,
        update_fund_loading: false,
        update_fund_error: action.payload,
      };
    default:
      return state;
  }
};
