import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProfileCard.module.css";

const ProfileCard = ({ firstName, lastName, email }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.star}>
        {/* <i className="fas fa-star"></i> */}
        <i className="fas fa-user-shield"></i>
      </div>
      <h5>{`${firstName} ${lastName}`}</h5>
      <p>{email}</p>
      <button onClick={() => navigate("/profile")}>View Profile</button>
    </div>
  );
};

export default ProfileCard;
