import React, { useState } from "react";
import Backdrop from "../../Backdrop/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Nisab.module.css";
import { UpdateNisab } from "../../../redux/updateNisab/action";
import { useEffect } from "react";
import { GetNisab } from "../../../redux/getNisab/action";

const Nisab = ({ setIsOpen }) => {
  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  const dispatch = useDispatch();

  const [nisabNaira, setNisabNaira] = useState();
  const [nisabPound, setNisabPound] = useState();
  const [nisabId, setNisabId] = useState();

  const { get_nisab_data } = useSelector((state) => state.getNisabReducer);

  useEffect(() => {
    dispatch(GetNisab(token));
  }, []);

  useEffect(() => {
    if (get_nisab_data) {
      setNisabNaira(get_nisab_data.data[0].nisab_naira);
      setNisabPound(get_nisab_data.data[0].nisab_pound);
      setNisabId(get_nisab_data.data[0]._id);
    }
  }, [get_nisab_data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      nisab_naira: nisabNaira,
      nisab_pound: nisabPound,
    };

    dispatch(UpdateNisab(token, nisabId, payload));

    setIsOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <Backdrop />
      {get_nisab_data && (
        <div className={styles.container}>
          <span className={styles.times} onClick={() => setIsOpen(false)}>
            &times;
          </span>
          <form onSubmit={handleSubmit}>
            <div className={styles.field}>
              <label>Nisab in Pound</label>
              <input
                type="number"
                name="amount"
                value={nisabPound}
                step="any"
                onChange={(e) => setNisabPound(e.target.value)}
              />
            </div>

            <div className={styles.field}>
              <label>Nisab in Naira</label>
              <input
                type="number"
                name="amount"
                value={nisabNaira}
                step="any"
                onChange={(e) => setNisabNaira(e.target.value)}
              />
            </div>

            <button type="submit">Update</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Nisab;
