import { GetUsersActionTypes } from "./types";
import { getUsers } from "../../api/index";

export const GetUsers = (token) => async (dispatch) => {
  try {
    dispatch({ type: GetUsersActionTypes.GET_USERS_LOADING });
    const { data } = await getUsers(token);
    dispatch({
      type: GetUsersActionTypes.GET_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GetUsersActionTypes.GET_USERS_ERROR,
      payload: error.response,
    });
  }
};
