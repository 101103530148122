import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createQuiz } from "../../api";
import Layout from "../../components/Layout/Layout";
import DateTimePicker from "react-datetime-picker";
import styles from "./CreateQuiz.module.css";

const CreateQuiz = () => {
  const navigate = useNavigate();
  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [winPrice, setWinPrice] = useState("");

  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const [optionA, setoptionA] = useState("");
  const [optionB, setoptionB] = useState("");
  const [optionC, setoptionC] = useState("");
  const [optionD, setoptionD] = useState("");
  const [answer, setAnswer] = useState("A");

  const [value, onChange] = useState(new Date());

  const handleSubmit = async () => {
    const payload = {
      questions,
      title,
      description,
      win_price: winPrice,
      quiz_date: value.toISOString(),
    };

    try {
      const res = await createQuiz(token, payload);
      console.log(res);
      if (res.status === 200) {
        navigate("/quiz");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Create Quiz</h1>
        </div>

        <div className={styles.card}>
          <h4>General Info</h4>
          <form>
            <div className={styles.field}>
              <input
                type="number"
                name="winPrice"
                value={winPrice}
                onChange={(e) => setWinPrice(e.target.value)}
                placeholder="Win Price"
                className={styles.input}
              />
            </div>

            <div className={styles.field}>
              <DateTimePicker
                onChange={onChange}
                value={value}
                className={styles.calendar}
              />
            </div>
          </form>
        </div>

        <div className={styles.card}>
          <h4>Questions</h4>

          {questions.length > 0 &&
            questions.map((i, idx) => (
              <div className={styles.submittedQuestion}>
                <p>
                  <span>Q{idx + 1}.</span>
                  {i.questions}
                </p>
                <i
                  className="fas fa-trash"
                  onClick={() => {
                    setQuestions((prev) =>
                      prev.filter((j) => j.questions !== i.questions)
                    );
                  }}
                ></i>
              </div>
            ))}

          <div className={styles.questionContainer}>
            <div className={styles.field}>
              <input
                type="text"
                name="question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Question"
                className={styles.input}
              />
            </div>
            <div className={styles.field}>
              <input
                type="text"
                name="option1"
                value={optionA}
                onChange={(e) => setoptionA(e.target.value)}
                placeholder="Option A"
                className={styles.input}
              />
            </div>
            <div className={styles.field}>
              <input
                type="text"
                name="option2"
                value={optionB}
                onChange={(e) => setoptionB(e.target.value)}
                placeholder="Option B"
                className={styles.input}
              />
            </div>
            <div className={styles.field}>
              <input
                type="text"
                name="option3"
                value={optionC}
                onChange={(e) => setoptionC(e.target.value)}
                placeholder="Option C"
                className={styles.input}
              />
            </div>
            <div className={styles.field}>
              <input
                type="text"
                name="option4"
                value={optionD}
                onChange={(e) => setoptionD(e.target.value)}
                placeholder="Option D"
                className={styles.input}
              />
            </div>
            <div className={styles.field}>
              <select
                name="answer"
                placeholder="Answer"
                onChange={(e) => setAnswer(e.target.value)}
              >
                <option value="answer" disabled>
                  Answer
                </option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
              </select>
            </div>
            <button
              className={styles.btn}
              onClick={() => {
                if (
                  question &&
                  optionA &&
                  optionB &&
                  optionC &&
                  optionD &&
                  answer
                ) {
                  const obj = {
                    questions: question,
                    option1: optionA,
                    option2: optionB,
                    option3: optionC,
                    option4: optionD,
                    answer:
                      answer === "A"
                        ? optionA
                        : answer === "B"
                        ? optionB
                        : answer === "C"
                        ? optionC
                        : optionD,
                  };
                  setQuestions((prev) => [...prev, obj]);
                  setQuestion("");
                  setoptionA("");
                  setoptionB("");
                  setoptionC("");
                  setoptionD("");
                  setAnswer("");
                }
              }}
            >
              Submit Question
            </button>
          </div>
        </div>

        <button className={styles.btn} onClick={handleSubmit}>
          Create Quiz
        </button>
      </div>
    </Layout>
  );
};

export default CreateQuiz;
