import React, { useEffect, useState } from "react";
import Backdrop from "../../Backdrop/Backdrop";
import axios from "axios";
import styles from "./EditTrusteeModal.module.css";

const EditTrusteeModal = ({ setIsOpen, trusteeID, trustees, setTrustees }) => {
  const [trustee, setTrustee] = useState(null);
  const [trusteeImg, setTrusteeImg] = useState(null);
  const [trusteeName, setTrusteeName] = useState("");
  const [trusteeWeb, setTrusteeWeb] = useState("");
  const [trusteeLinkedin, setTrusteeLinkedin] = useState("");

  useEffect(() => {
    const currentTrustee = trustees.find((i) => i._id === trusteeID);
    setTrustee(currentTrustee);
    setTrusteeImg(currentTrustee.image);
    setTrusteeName(currentTrustee.name);
    setTrusteeWeb(currentTrustee.website);
    setTrusteeLinkedin(currentTrustee.linkedin);
  }, []);

  let formdata = new FormData();
  const onImgChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let imgg = event.target.files[0];
      formdata.append("image", imgg);
      axios
        .post(`https://api.tutlub.com/api/user/upload`, formdata, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setTrusteeImg(res.data.image);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSubmit = () => {
    const removedArray = trustees.filter((i) => i._id !== trusteeID);

    removedArray.push({
      image: trusteeImg,
      name: trusteeName,
      website: trusteeWeb,
      linkedin: trusteeLinkedin,
    });

    setTrustees(removedArray);
    setIsOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <Backdrop />
      {trustee && (
        <div className={styles.container}>
          <span className={styles.times} onClick={() => setIsOpen(false)}>
            &times;
          </span>
          <h2>Edit Trustee</h2>

          <div className={styles.field}>
            <label>Image</label>
            <div className={styles.imgContainer}>
              <img src={trusteeImg} alt={trusteeName} />
              <i className={styles.cam + " fas fa-camera"}></i>
              <input type="file" onChange={onImgChange} />
            </div>
          </div>

          <div className={styles.field}>
            <label>Name</label>
            <input
              type="text"
              value={trusteeName}
              onChange={(e) => setTrusteeName(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <label>Website</label>
            <input
              type="text"
              value={trusteeWeb}
              onChange={(e) => setTrusteeWeb(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <label>Linkedin</label>
            <input
              type="text"
              value={trusteeLinkedin}
              onChange={(e) => setTrusteeLinkedin(e.target.value)}
            />
          </div>

          <button onClick={handleSubmit}>Update</button>
        </div>
      )}
    </div>
  );
};

export default EditTrusteeModal;
