import React from "react";
import Header from "./Header/Header";
import SideSection from "./SideSection/SideSection";
import styles from "./Layout.module.css";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <SideSection />
      <main>{children}</main>
    </>
  );
};

export default Layout;
