import { format } from "date-fns";
import { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { useNavigate } from "react-router-dom";
import { createQuiz, getLastQuiz, getQuiz, updateQuiz } from "../../api";
import Layout from "../../components/Layout/Layout";
import styles from "./UpdateQuiz.module.css";

const UpdateQuiz = () => {
  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  const navigate = useNavigate();
  const [quizDetails, setQuizDetails] = useState();
  const [isNew, setIsNew] = useState(false);

  const [value, onChange] = useState(new Date());
  const [winPrice, setWinPrice] = useState();
  const [questions, setQuestions] = useState([]);
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [optionA, setoptionA] = useState("");
  const [optionB, setoptionB] = useState("");
  const [optionC, setoptionC] = useState("");
  const [optionD, setoptionD] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const res = await getQuiz(token);

        if (res.data.message !== "No Upcoming Quiz") {
          setIsNew(false);
          setQuizDetails(res.data.data[0]);
          onChange(new Date(res.data.data[0].quiz_date));
          setWinPrice(res.data.data[0].win_price);
          setQuestions(res.data.data[0].questions);
        } else {
          setIsNew(true);
          const lastQuiz = await getLastQuiz(token);
          setQuizDetails(lastQuiz.data.data);
          onChange(new Date(lastQuiz.data.data.quiz_date));
          setWinPrice(lastQuiz.data.data.win_price);
          setQuestions(lastQuiz.data.data.questions);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchQuiz();
  }, []);

  const handleSubmit = async () => {
    const payload = {
      questions,
      win_price: winPrice,
      quiz_date: value.toISOString(),
    };

    if (isNew) {
      try {
        const res = await createQuiz(token, payload);
        if (res.status === 200) {
          navigate("/quiz");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await updateQuiz(token, payload);
        if (res.status === 200) {
          navigate("/quiz");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Update Quiz</h1>
        </div>

        <div className={styles.card}>
          <h4>General Info</h4>

          <div className={styles.field}>
            <label>Quiz Date</label>
            <div className={styles.field}>
              <DateTimePicker
                onChange={onChange}
                value={value}
                className={styles.calendar}
              />
            </div>
          </div>

          <div className={styles.field}>
            <label>Win Price</label>
            <input
              type="text"
              className={styles.input}
              value={winPrice}
              onChange={(e) => setWinPrice(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.card}>
          <h4>Questions</h4>
          <button
            className={`${styles.addQuestion} ${styles.btn}`}
            onClick={(e) => setIsBoxOpen(true)}
          >
            Add Question
          </button>

          {isBoxOpen && (
            <div className={styles.questionContainer}>
              <div className={styles.field}>
                <input
                  type="text"
                  name="question"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder="Question"
                  className={styles.input}
                />
              </div>
              <div className={styles.field}>
                <input
                  type="text"
                  name="option1"
                  value={optionA}
                  onChange={(e) => setoptionA(e.target.value)}
                  placeholder="Option A"
                  className={styles.input}
                />
              </div>
              <div className={styles.field}>
                <input
                  type="text"
                  name="option2"
                  value={optionB}
                  onChange={(e) => setoptionB(e.target.value)}
                  placeholder="Option B"
                  className={styles.input}
                />
              </div>
              <div className={styles.field}>
                <input
                  type="text"
                  name="option3"
                  value={optionC}
                  onChange={(e) => setoptionC(e.target.value)}
                  placeholder="Option C"
                  className={styles.input}
                />
              </div>
              <div className={styles.field}>
                <input
                  type="text"
                  name="option4"
                  value={optionD}
                  onChange={(e) => setoptionD(e.target.value)}
                  placeholder="Option D"
                  className={styles.input}
                />
              </div>
              <div className={styles.field}>
                <select
                  name="answer"
                  placeholder="Answer"
                  onChange={(e) => setAnswer(e.target.value)}
                >
                  <option value="answer" disabled>
                    Answer
                  </option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                </select>
              </div>
              <button
                className={`${styles.add} ${styles.btn}`}
                onClick={() => {
                  if (
                    question &&
                    optionA &&
                    optionB &&
                    optionC &&
                    optionD &&
                    answer
                  ) {
                    const obj = {
                      questions: question,
                      option1: optionA,
                      option2: optionB,
                      option3: optionC,
                      option4: optionD,
                      answer:
                        answer === "A"
                          ? optionA
                          : answer === "B"
                          ? optionB
                          : answer === "C"
                          ? optionC
                          : optionD,
                    };
                    setQuestions((prev) => [...prev, obj]);
                    setQuestion("");
                    setoptionA("");
                    setoptionB("");
                    setoptionC("");
                    setoptionD("");
                    setAnswer("");
                    setIsBoxOpen(false);
                  }
                }}
              >
                Add
              </button>
            </div>
          )}

          {questions &&
            questions.map((i, idx) => (
              <div className={styles.submittedQuestion}>
                <p>
                  <span>Q{idx + 1}.</span>
                  {i.questions}
                </p>
                <i
                  className="fas fa-trash"
                  onClick={() => {
                    setQuestions((prev) =>
                      prev.filter((j) => j.questions !== i.questions)
                    );
                  }}
                ></i>
              </div>
            ))}
        </div>

        <button
          className={`${styles.add} ${styles.btn}`}
          onClick={handleSubmit}
        >
          Save Changes
        </button>
      </div>
    </Layout>
  );
};

export default UpdateQuiz;
