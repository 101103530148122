import { RegisterActionTypes } from "./types";
import { register } from "../../api/index";

export const RegisterUser = (credentials, navigate) => async (dispatch) => {
  try {
    dispatch({ type: RegisterActionTypes.REGISTER_LOADING });
    const { data } = await register(credentials);
    dispatch({
      type: RegisterActionTypes.REGISTER_SUCCESS,
      payload: data,
    });

    localStorage.setItem("token", JSON.stringify(data.data.jwt));
    navigate("/");

  } catch (error) {
    dispatch({
      type: RegisterActionTypes.REGISTER_ERROR,
      payload: error.response,
    });
  }
};
