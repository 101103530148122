import { GetFundByIDActionTypes } from "./types";
let INITIAL_STATE = {
  get_fund_by_id_loading: false,
  get_fund_by_id_error: null,
  get_fund_by_id_data: null,
};

export const getFundByIDReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GetFundByIDActionTypes.GET_FUND_BY_ID_LOADING:
      return { ...state, get_fund_by_id_loading: true };
    case GetFundByIDActionTypes.GET_FUND_BY_ID_SUCCESS:
      return {
        ...state,
        get_fund_by_id_loading: false,
        get_fund_by_id_data: action.payload,
      };
    case GetFundByIDActionTypes.GET_FUND_BY_ID_ERROR:
      return {
        ...state,
        get_fund_by_id_loading: false,
        get_fund_by_id_error: action.payload,
      };
    default:
      return state;
  }
};
