import { LoginActionTypes } from "./types";
let INITIAL_STATE = {
  login_loading: false,
  login_error: null,
  login_data: null,
};

export const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoginActionTypes.LOGIN_LOADING:
      return { ...state, login_loading: true };
    case LoginActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        login_loading: false,
        user_data: action.payload,
      };
    case LoginActionTypes.LOGIN_ERROR:
      return {
        ...state,
        login_loading: false,
        login_error: action.payload,
      };
    default:
      return state;
  }
};
