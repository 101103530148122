import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import Create from "../../components/_tidbits/Create/Create";
import Cards from "../../containers/_tidbits/Cards/Cards";
import styles from "./Tidbits.module.css";

const Tidbits = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  return (
    <>
      <Layout>
        <div className={styles.container}>
          <div className={styles.header}>
            <h1>Tidbits</h1>
            <button onClick={() => setIsOpen(true)}>Create</button>
          </div>

          <Cards token={token} />
        </div>
      </Layout>
      {isOpen && <Create setIsOpen={setIsOpen} />}
    </>
  );
};

export default Tidbits;
