import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteTidbit } from "../../../redux/deleteTidbit/action";
import { format } from 'date-fns'
import styles from "./Card.module.css";

const Card = ({ title, id, body, token, date }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(DeleteTidbit(token, id));
  };
  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      <div className={styles.body}>
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </div>
      <p className={styles.readMore} onClick={() => navigate(`/tidbits/${id}`)}>
        Read More <i className="fas fa-angle-right"></i>
      </p>
      <p className={styles.trash} onClick={handleDelete}>
        Delete
      </p>
      <small><i className="fas fa-clock"></i>{" "}{format(new Date(date), 'EEE, dd MMM yyyy')}</small>
    </div>
  );
};

export default Card;
