import pic from "../../assets/spinner.png";
import styles from "./Spinner.module.css";

const Spinner = () => (
  <img
    src={pic}
    alt="spinner"
    className={styles.spinner}
  />
);

export default Spinner;
