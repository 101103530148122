import { UpdateFundActionTypes } from "./types";
import { updateFund } from "../../api/index";

export const UpdateFund = (token, id, userData) => async (dispatch) => {
  try {
    dispatch({ type: UpdateFundActionTypes.UPDATE_FUND_LOADING });
    const { data } = await updateFund(token, id, userData);
    dispatch({
      type: UpdateFundActionTypes.UPDATE_FUND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UpdateFundActionTypes.UPDATE_FUND_ERROR,
      payload: error.response,
    });
  }
};
