import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import styles from "./Users.module.css";
import placeholder from "../../assets/placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import { GetUsers } from "../../redux/getUsers/action";
import Loader from "../../components/Loader/Loader";
import { DeleteUser } from "../../redux/deleteUser/action";

const Users = () => {
  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  const dispatch = useDispatch();

  const { get_users_data, get_users_loading } = useSelector(
    (state) => state.getUsersReducer
  );

  const { delete_user_data, delete_user_loading } = useSelector(
    (state) => state.deleteUserReducer
  );

  useEffect(() => {
    dispatch(GetUsers(token));
  }, [delete_user_data]);

  const deleteUser = (id) => {
    dispatch(DeleteUser(id, token));
  };

  return (
    <Layout>
      <div className={styles.container}>
        <h1>Users</h1>

        {!get_users_data || get_users_loading ? (
          <Loader />
        ) : (
          <>
            <div className={styles.header}>
              <p>User</p>
              <p>Email</p>
              <p>Phone</p>
              <p>Country</p>
              <p>Status</p>
              <p>Actions</p>
            </div>

            <div className={styles.body}>
              <ul>
                {get_users_data.data.map((user) => (
                  <li className={styles.user} key={user._id}>
                    <div className={styles.name}>
                      <img
                        src={user.image ? user.image : placeholder}
                        alt="user-pic"
                      />
                      {user.first_name ? (
                        <p>
                          {user.first_name} {user.last_name}
                        </p>
                      ) : (
                        <p>None</p>
                      )}
                    </div>
                    <div>
                      <p>{user.email ? user.email : "None"}</p>
                    </div>
                    <div>
                      <p>{user.mobile}</p>
                    </div>
                    <div>
                      <p>{user.country ? user.country : "None"}</p>
                    </div>
                    <div>
                      <p
                        className={
                          user.status === "live" ? styles.live : styles.pending
                        }
                      >
                        {user.status}
                      </p>
                    </div>
                    <div onClick={() => deleteUser(user._id)}>
                      <i className="fas fa-trash"></i>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Users;
