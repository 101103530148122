import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import Loader from "../../../components/Loader/Loader";
import { LoginUser } from "../../../redux/login/action";
import { login } from "../../../api";
import styles from "../Auth.module.css";
import Spinner from "../../../components/Spinner/Spinner";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { login_error, login_loading } = useSelector(
    (state) => state.loginReducer
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  // Get Token from LocalStorage
  const tokenFromLocalStorage = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;

  useEffect(() => {
    if (tokenFromLocalStorage) {
      navigate("/");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({
      email: "",
      password: "",
    });
    let errorsCopy = { ...errors };
    let isErr = false;
    if (!email) {
      isErr = true;
      errorsCopy.email = "Email is Required";
    }
    if (!password) {
      isErr = true;
      errorsCopy.password = "Password is Required";
    }
    if (isErr) {
      setErrors({ ...errorsCopy });
    } else {
      const payload = { email, password };

      setLoading(true);
      try {
        const res = await login(payload);

        localStorage.setItem("token", JSON.stringify(res.data.data.jwt));
        navigate("/");
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}></div>
      <div className={styles.rightContainer}>
        <div className={`${styles.content} ${styles.contentLogin}`}>
          <Logo />
          <h1>Sign in</h1>
          <form onSubmit={handleSubmit}>
            <div className={styles.field}>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="admin@tutlub.com"
              />
              <small className="error">{errors.email}</small>
            </div>
            <div className={styles.field}>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;"
              />
              <small className="error">{errors.password}</small>
            </div>
            {login_error && (
              <small className="error">{login_error.data.message}</small>
            )}
            <button>{loading ? <Spinner /> : "Sign in"}</button>
            <small
              onClick={() => navigate("/register")}
              className={styles.small}
            >
              Don't have an account? Sign Up!
            </small>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
