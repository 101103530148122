import { GetTidbitsByIDActionTypes } from "./types";
let INITIAL_STATE = {
  get_tidbits_by_id_loading: false,
  get_tidbits_by_id_error: null,
  get_tidbits_by_id_data: null,
};

export const getTidbitsByIDReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GetTidbitsByIDActionTypes.GET_TIDBITS_BY_ID_LOADING:
      return { ...state, get_tidbits_by_id_loading: true };
    case GetTidbitsByIDActionTypes.GET_TIDBITS_BY_ID_SUCCESS:
      return {
        ...state,
        get_tidbits_by_id_loading: false,
        get_tidbits_by_id_data: action.payload,
      };
    case GetTidbitsByIDActionTypes.GET_TIDBITS_BY_ID_ERROR:
      return {
        ...state,
        get_tidbits_by_id_loading: false,
        get_tidbits_by_id_error: action.payload,
      };
    default:
      return state;
  }
};
