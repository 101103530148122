import React, { useState } from "react";
import Backdrop from "../../Backdrop/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Create.module.css";
import { CreateFund } from "../../../redux/createFund/action";
import { uploadMedia } from "../../../api";

const Create = ({ setIsOpen }) => {
  let formdata = new FormData();
  // Get Token from LocalStorage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null;
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [convertedText, setConvertedText] = useState("");
  const [amount, setAmount] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [trustees, setTrustees] = useState([]);
  const [status, setStatus] = useState();
  const [trusteeName, setTrusteeName] = useState();
  const [trusteeImage, setTrusteeImage] = useState("");
  const [trusteeWebsite, setTrusteeWebsite] = useState();
  const [trusteeLinkedin, setTrusteeLinkedin] = useState();

  const [isTrusteeOpen, setIsTrusteeOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      title,
      description: convertedText,
      amount_required: amount,
      deadline_date: startDate,
      trustees,
      status: status ? "live" : "inactive",
    };

    console.log(payload, "PAYLOAD");

    dispatch(CreateFund(token, payload));

    setIsOpen(false);
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
  ];

  const addTrustee = () => {
    const trustee = {
      name: trusteeName,
      image: trusteeImage,
      website: trusteeWebsite,
      linkedin: trusteeLinkedin,
    };

    setTrustees((prev) => [...prev, trustee]);

    setTrusteeImage("");
    setTrusteeName("");
    setTrusteeWebsite("");
    setTrusteeLinkedin("");
    setIsTrusteeOpen(false);
  };

  const handleImageChange = async (e) => {
    let file = e.target.files[0];

    if (file) {
      formdata.append("image", file);
      try {
        let { data } = await uploadMedia(formdata);
        setTrusteeImage((data.image))
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className={styles.mainContainer}>
      <Backdrop />
      <div className={styles.container}>
        <span className={styles.times} onClick={() => setIsOpen(false)}>
          &times;
        </span>
        <form onSubmit={handleSubmit}>
          <div className={styles.field}>
            <label>Title</label>
            <input
              type="text"
              name="title"
              placeholder="Write something"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className={styles.field}>
            <label>Description</label>
            <div>
              <ReactQuill
                theme="snow"
                value={convertedText}
                onChange={setConvertedText}
                style={{ minHeight: "300px" }}
                formats={formats}
              />
            </div>
          </div>

          <div className={styles.field}>
            <label>Amount Required</label>
            <input
              type="number"
              name="amount"
              required
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <label>Deadline</label>
            <DatePicker
              selected={startDate}
              minDate={new Date()}
              showDisabledMonthNavigation
              onChange={(date) => setStartDate(date)}
            />
          </div>

          <div className={styles.field}>
            <label>Trustees</label>
            {!isTrusteeOpen ? (
              <button
                className={styles.addTrusteeBtn}
                type="button"
                onClick={() => setIsTrusteeOpen(true)}
              >
                Add+
              </button>
            ) : (
              <>
                <div className={styles.trusteeField}>
                  <input
                    type="file"
                    // name="trusteeImage"
                    // value={trusteeImage}
                    // onChange={(e) => setTrusteeImage(e.target.files[0])}
                    onChange={handleImageChange}
                  />
                  <input
                    type="text"
                    name="trusteeName"
                    placeholder="Name"
                    value={trusteeName}
                    onChange={(e) => setTrusteeName(e.target.value)}
                  />
                  <input
                    type="text"
                    name="trusteeWebsite"
                    placeholder="Website"
                    value={trusteeWebsite}
                    onChange={(e) => setTrusteeWebsite(e.target.value)}
                  />
                  <input
                    type="text"
                    name="trusteeLinkedin"
                    placeholder="Linkedin"
                    value={trusteeLinkedin}
                    onChange={(e) => setTrusteeLinkedin(e.target.value)}
                  />
                </div>
                <button
                  className={styles.addTrusteeBtn}
                  type="button"
                  onClick={addTrustee}
                >
                  Save
                </button>
              </>
            )}
          </div>

          <div className={styles.field}>
            <label>Status</label>
            <input
              type="checkbox"
              name="status"
              required
              value={status}
              onChange={(e) => setStatus(!status)}
            />
          </div>

          <button type="submit">Post</button>
        </form>
      </div>
    </div>
  );
};

export default Create;
