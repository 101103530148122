import { GetProfileActionTypes } from "./types";
import { getProfile } from "../../api/index";

export const GetProfile = (tokenFromLocalStorage) => async (dispatch) => {
  try {
    dispatch({ type: GetProfileActionTypes.GET_PROFILE_LOADING });
    const { data } = await getProfile(tokenFromLocalStorage);
    dispatch({
      type: GetProfileActionTypes.GET_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GetProfileActionTypes.GET_PROFILE_ERROR,
      payload: error.response,
    });
  }
};
