import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserCard from "../../../components/_home/UserCard/UserCard";
import styles from "./Users.module.css";

const Users = ({ data }) => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    if (data) {
      if (data.data.length >= 6) {
        setUsers(data.data.slice(0, 6));
      } else {
        setUsers(data.data);
      }
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <h3>Users</h3>
      <div className={styles.usersContainer}>
        {users.map((user) => (
          <UserCard
            key={user._id}
            img={user.image}
            firstName={user.first_name}
            lastName={user.last_name}
            country={user.country}
          />
        ))}
      </div>
      <button onClick={() => navigate("/users")}>View all</button>
    </div>
  );
};

export default Users;
