import { UpdateTidbitActionTypes } from "./types";
import { updateTidbit } from "../../api/index";

export const UpdateTidbit = (token, id, userData) => async (dispatch) => {
  try {
    dispatch({ type: UpdateTidbitActionTypes.UPDATE_TIDBIT_LOADING });
    const { data } = await updateTidbit(token, id, userData);
    dispatch({
      type: UpdateTidbitActionTypes.UPDATE_TIDBIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UpdateTidbitActionTypes.UPDATE_TIDBIT_ERROR,
      payload: error.response,
    });
  }
};
