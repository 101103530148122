import { DeleteFundActionTypes } from "./types";
let INITIAL_STATE = {
  delete_fund_loading: false,
  delete_fund_error: null,
  delete_fund_data: null,
};

export const deleteFundReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeleteFundActionTypes.DELETE_FUND_LOADING:
      return { ...state, delete_fund_loading: true };
    case DeleteFundActionTypes.DELETE_FUND_SUCCESS:
      return {
        ...state,
        delete_fund_loading: false,
        delete_fund_data: action.payload,
      };
    case DeleteFundActionTypes.DELETE_FUND_ERROR:
      return {
        ...state,
        delete_fund_loading: false,
        delete_fund_error: action.payload,
      };
    default:
      return state;
  }
};
