import { RegisterActionTypes } from "./types";
let INITIAL_STATE = {
  register_loading: false,
  register_error: null,
  register_data: null,
};

export const registerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RegisterActionTypes.REGISTER_LOADING:
      return { ...state, register_loading: true };
    case RegisterActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        register_loading: false,
        register_data: action.payload,
      };
    case RegisterActionTypes.REGISTER_ERROR:
      return {
        ...state,
        register_loading: false,
        register_error: action.payload,
      };
    default:
      return state;
  }
};
