import { GetFundsActionTypes } from "./types";
import { getFunds } from "../../api/index";

export const GetFunds = (token) => async (dispatch) => {
  try {
    dispatch({ type: GetFundsActionTypes.GET_FUNDS_LOADING });
    const { data } = await getFunds(token);
    dispatch({
      type: GetFundsActionTypes.GET_FUNDS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GetFundsActionTypes.GET_FUNDS_ERROR,
      payload: error.response,
    });
  }
};
