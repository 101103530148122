import React, { useState } from "react";
import { ReactComponent as Admin } from "../../../assets/admin.svg";
import AdminDropdown from "../../AdminDropdown/AdminDropdown";
import { ReactComponent as Logout } from "../../../assets/logout.svg";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <header className={styles.container}>
      <i className="fas fa-envelope"></i>
      <Logout onClick={logout} />
      {/* <div
        className={styles.admin}
        onMouseOver={() => setIsOpen(true)}
        onMouseOut={() => setIsOpen(false)}
      >
        <Admin />
        <p>Ade Hassan</p>
        <i className={`fas fa-chevron-${isOpen ? "up" : "down"}`}></i>
      </div>

      <span className={styles.adminBack} onMouseOver={() => setIsOpen(true)} />

      {isOpen && <AdminDropdown setIsOpen={setIsOpen} />} */}
    </header>
  );
};

export default Header;
