import { CreateFundActionTypes } from "./types";
import { createFund } from "../../api/index";

export const CreateFund = (token, userData) => async (dispatch) => {
  try {
    dispatch({ type: CreateFundActionTypes.CREATE_FUND_LOADING });
    const { data } = await createFund(token, userData);
    dispatch({
      type: CreateFundActionTypes.CREATE_FUND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CreateFundActionTypes.CREATE_FUND_ERROR,
      payload: error.response,
    });
  }
};
