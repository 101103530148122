import React from "react";
import { ReactComponent as FundIcon } from "../../../assets/home/fund.svg";
import styles from "./FundCard.module.css";

const FundCard = ({ amount, name }) => {
  return (
    <div className={styles.container}>
      <FundIcon />
      <div className={styles.textContainer}>
        <h4>{amount}</h4>
        <p>{name}</p>
      </div>
      <i className="fas fa-caret-right"></i>
    </div>
  );
};

export default FundCard;
