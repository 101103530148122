import { combineReducers } from "redux";
import { createTidbitReducer } from "./createTidbit/reducer";
import { deleteTidbitReducer } from "./deleteTidbit/reducer";
import { deleteUserReducer } from "./deleteUser/reducer";
import { getProfileReducer } from "./getProfile/reducer";
import { getTidbitsReducer } from "./getTidbits/reducer";
import { getTidbitsByIDReducer } from "./getTidbitsByID/reducer";
import { getUsersReducer } from "./getUsers/reducer";
import { loginReducer } from "./login/reducer";
import { registerReducer } from "./register/reducer";
import { updateProfileReducer } from "./updateProfile/reducer";
import { updateTidbitReducer } from "./updateTidbit/reducer";
import { getFundsReducer } from "./getFunds/reducer";
import { createFundReducer } from "./createFund/reducer";
import { deleteFundReducer } from "./deleteFund/reducer";
import { getFundByIDReducer } from "./getFundByID/reducer";
import { updateFundReducer } from "./updateFund/reducer";
import { updateNisabReducer } from "./updateNisab/reducer";
import { getNisabReducer } from "./getNisab/reducer";

export default combineReducers({
  loginReducer,
  registerReducer,
  getProfileReducer,
  updateProfileReducer,
  getUsersReducer,
  deleteUserReducer,
  getTidbitsReducer,
  getTidbitsByIDReducer,
  deleteTidbitReducer,
  createTidbitReducer,
  updateTidbitReducer,
  getFundsReducer,
  createFundReducer,
  deleteFundReducer,
  getFundByIDReducer,
  updateFundReducer,
  updateNisabReducer,
  getNisabReducer,
});