import { UpdateNisabActionTypes } from "./types";
let INITIAL_STATE = {
  update_nisab_loading: false,
  update_nisab_error: null,
  update_nisab_data: null,
};

export const updateNisabReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UpdateNisabActionTypes.UPDATE_NISAB_LOADING:
      return { ...state, update_nisab_loading: true };
    case UpdateNisabActionTypes.UPDATE_NISAB_SUCCESS:
      return {
        ...state,
        update_nisab_loading: false,
        update_nisab_data: action.payload,
      };
    case UpdateNisabActionTypes.UPDATE_NISAB_ERROR:
      return {
        ...state,
        update_nisab_loading: false,
        update_nisab_error: action.payload,
      };
    default:
      return state;
  }
};
