import { GetNisabActionTypes } from "./types";
let INITIAL_STATE = {
  get_nisab_loading: false,
  get_nisab_error: null,
  get_nisab_data: null,
};

export const getNisabReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GetNisabActionTypes.GET_NISAB_LOADING:
      return { ...state, get_nisab_loading: true };
    case GetNisabActionTypes.GET_NISAB_SUCCESS:
      return {
        ...state,
        get_nisab_loading: false,
        get_nisab_data: action.payload,
      };
    case GetNisabActionTypes.GET_NISAB_ERROR:
      return {
        ...state,
        get_nisab_loading: false,
        get_nisab_error: action.payload,
      };
    default:
      return state;
  }
};
